import React from "react";
import ReactDOM from "react-dom";

const App = () => {
  return (
    <div id="gossipbrewing-app">
      <div className="c62">
        <div className="gossipBrewingSuperCentered"></div>
        <div className="c20banner"></div>
      </div>
      <div className="c38">
        <div className="c20text">
          <p>
            <b>
              Brewed and Bottled by Gossip Brewing © 2023, Broomfield, CO, USA.
            </b>
          </p>
          <p>
            <a href="attributes/">attributes</a>
          </p>
        </div>
      </div>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
